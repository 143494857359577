import React, { Component } from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { Link } from 'gatsby';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import BreadCrumbs from '../components/BreadCrumbs';

import '../assets/sass/main.scss';
import '../components/i18n';
import { i18n } from 'i18next';

interface IProps {
  pageContext: {
    tag: string,
    tagPosts: any,
  },
  data: {
    allMarkdownRemark: {
      totalCount: number,
      edges: Array<{
        node: {
          frontmatter: {
            path: string,
            titel: string,
          },
        },
      }>
    }
  },
  t?: TranslationFunction,
  i18n?: i18n,
}

class Tags extends Component<IProps> {
  render() {
    const { pageContext: { tag, tagPosts: edges }, t, i18n } = this.props;
    const totalCount = edges.length;
    return (
      <div className="main-wrapper">
        <Header/>
          <BreadCrumbs breadcrumbs={[
            {
              text: 'Home',
              href: {
                nl: '',
                en: '',
              },
            },
            {
              text: 'Blog',
              href: {
                nl: 'blog/',
                en: 'blog/',
              },
            },
            {
              text: 'Tags',
              href: {
                nl: 'tags/',
                en: 'tags/',
              },
            },
            {
              text: `Tag: ${tag}`,
            }
          ]}
          />
          <section id="main" className="wrapper">
            <div className="inner">
              <h1 className="major">{`${totalCount} post${
                totalCount === 1 ? "" : "s"
                } ${t('tagged with')} "${tag}"`}</h1>
              <ul>
                {edges.map(({ node }: any) => {
                  const { path, title } = node.frontmatter;
                  return (
                    <li key={path}>
                      <Link to={path}>{title}</Link>
                    </li>
                  );
                })}
              </ul>
              <Link to={`/${i18n.language}/tags`}>{t('All tags')}</Link>
            </div>
          </section>
        <Footer/>
      </div>
    );
  }
}

export default translate()(Tags);
